import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 275,
    flexGrow: 1,
    marginTop:"0rem",
  },

  appWelcome: {
    textAlign: "center",
    margin: theme.spacing(1),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  paper: {
    // textAlign: "center"
  },
  
  section:
  {
    margin: theme.spacing(4, 2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
	},
	large: {
		width: "8rem",
		height: "8rem",
    border: "solid 0.375rem #fff",
    // border: "solid 1px #000",
	},
  button:
  {
    borderRadius: 50,
    // color: '#000',
    boxShadow: 'none',
    margin: theme.spacing(1),
    backgroundColor: '#ccc',
    // border: "solid 0.25px #333",
    // border: "solid 0.25px #fff",
    "&:hover":{
      // background: "none",
      // backgroundColor: theme.palette.action.active,
      // backgroundColor: theme.palette.background.paper,
      // border: "solid 0.5px #fff",
      // color: "#000",
    },
	},
	
  
  canvasContainer: {
    // width: "100%",
    // height: "10rem",
    // margin: 0,
    // padding: 0,
    // backgroundColor: "red",
    // backgroundColor: "red",
    // position: "absolute",
  }
  
  // button:
});

export default styles;