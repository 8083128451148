import React from 'react';

import logo from './logo.svg';
import Image1 from "./images/IMG_0620.JPG";
import Image2 from "./images/DSC_0023.JPG";
import Image3 from "./images/DSC_0053.JPG";
import Image4 from "./images/DSC_0085.JPG";
import Image5 from "./images/DSC_0159.JPG";
import Image6 from "./images/DSC_0279.JPG";
import Image7 from "./images/DSC_0295.JPG";
import Image8 from "./images/DSC_0333.JPG";
import Image9 from "./images/IMG_0359.JPG";
import Image10 from "./images/IMG_1193.JPG";
import Image11 from "./images/DSC_0526.JPG";
import Image12 from "./images/IMG_2912.JPG";
import Image13 from "./images/IMG_0876.JPG";
import Image14 from "./images/IMG_2536.JPG";
import Image15 from "./images/IMG_0876.JPG";
import Image16 from "./images/IMG_0144.JPG";

import './App.css';
import './buttons.css';

import Typography from '@material-ui/core/Typography';
import { Avatar, Container, SvgIcon, ThemeProvider, withStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';

import styles from "./styles";
import classNames from 'classNames';
import Pins from "./Pins/Pins";
import FadingBanner from "./FadingBanner/FadingBanner";
import myTheme from "./Theme";




const fadeImages = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
];

const img = "DSC_0279.JPG";


interface IAppProps {
  classes: any;
}

class App extends React.Component<IAppProps, {}>
{
  constructor (props: any)
  {
    super(props);
  }

  public render = () => 
  {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={myTheme}>
        <div className="App">
          <Container
            maxWidth={false}
            className={"bannerTop"}
            // display="flex" 
            // flexDirection="column" p={1} bgcolor="background.paper" 
            style={
              {
                padding: 0,
                // height: "8rem",
                overflow: "hidden",
                // backgroundImage: `url('/${img}')`,
              }
            }
          >
            <FadingBanner images={fadeImages} fadeSpeed={1000} pauseDuration={5000}/>
            
            
            {/* <canvas className={classes.canvas} width="100%" height="10rem"></canvas> */}
          </Container>

          <Box display="flex" flexDirection="column" p={1} bgcolor="background.paper" justifyContent="center" alignItems="center"
          style={{position: "relative", paddingTop: "4.5em"}}
          className = {"banner"}
          >
            <Avatar 
              alt="Mark Forster"
              src="https://www.gravatar.com/avatar/872d6fa53ed3f389ec8e8337698bf2c7?s=256" 
              className={classNames(classes.large, "Avatar")} 
              style={{position: "absolute", top: "-4.5rem"}}
              />
            {/* <span>&#11044;</span> */}
            <Typography variant="h4">Hi.. I'm Mark <span className="love">♥</span></Typography>
            <Typography variant="body1">I'm a technologist, co-founder of <a href="https://hedgehoglab.com" target="_blank">hedgehog lab</a> and <a href="https://done-well.co.uk" target="_blank">done well</a>, husband, father and a dabbling musician and problem solving enthusiast.</Typography>
          </Box>

          <Container style={{ "marginTop": "1rem" }}>
            {/* <Box display="flex" flexDirection="row" p={1} bgcolor="background.paper" justifyContent="center" alignItems="center"> */}

            <Pins/>

            
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
