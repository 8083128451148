import React from 'react';
import styles from "./styles";

import { Avatar, Container, IconButton, SvgIcon, ThemeProvider, withStyles } from '@material-ui/core';
import classNames from 'classNames';

import { ReactComponent as TwitterIcon } from "./../icons/cib-twitter.svg";
import { ReactComponent as FacebookIcon } from './../icons/cib-facebook.svg';
import { ReactComponent as IntagramIcon } from './../icons/cib-instagram.svg';
import { ReactComponent as SnapchatIcon } from './../icons/cib-snapchat.svg';
import { ReactComponent as TikTokIcon } from './../icons/cib-tiktok.svg';
import { ReactComponent as TraktIcon } from './../icons/cib-trakt.svg';
import { ReactComponent as GitHubIcon } from './../icons/cib-github.svg';
import { ReactComponent as BitBucketIcon } from './../icons/cib-bitbucket.svg';
import { ReactComponent as GitLabIcon } from './../icons/cib-gitlab.svg';
import { ReactComponent as GoodReadsIcon } from './../icons/cib-goodreads.svg';
import { ReactComponent as LastFMIcon } from './../icons/cib-last-fm.svg';
import { ReactComponent as FlickrIcon } from './../icons/cib-flickr.svg';
import { ReactComponent as DiscordIcon } from './../icons/cib-discord.svg';
import { ReactComponent as FourSquareIcon } from './../icons/cib-foursquare.svg';
import { ReactComponent as DribbbleIcon } from './../icons/cib-dribbble.svg';
import { ReactComponent as NPMIcon } from './../icons/cib-npm.svg';
import { ReactComponent as PintrestIcon } from './../icons/cib-pinterest.svg';
import { ReactComponent as PSNIcon } from './../icons/cib-playstation.svg';
import { ReactComponent as RedditIcon } from './../icons/cib-reddit.svg';
import { ReactComponent as FlipBoardIcon } from './../icons/cib-flipboard.svg';
import { ReactComponent as SoundcloudIcon } from './../icons/cib-soundcloud.svg';
import { ReactComponent as TwitchIcon } from './../icons/cib-twitch.svg';
import { ReactComponent as TumblrIcon } from './../icons/cib-tumblr.svg';
import { ReactComponent as YoutubeIcon } from './../icons/cib-youtube.svg';
import { ReactComponent as LinkedinIcon } from './../icons/cib-linkedin.svg';


class Pins extends React.Component<{classes: any}, {}>
{
	public onClick = (url: string) => { window.open(url, '_blank'); }
	
	public render = () =>
	{
		const { classes } = this.props;

		return (<>
		<IconButton
			onClick={() => { this.onClick("https://twitter.com/markforster"); }}
			className={classNames("twitter", classes.button)}
			aria-label="Twitter"
		><TwitterIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.facebook.com/mark.forster"); }}
			className={classNames("facebook", classes.button)}
			aria-label="Facebook"
		><FacebookIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.linkedin.com/in/markforster"); }}
			className={classNames("linkedin", classes.button)}
		><LinkedinIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.instagram.com/markforster/"); }}
			className={classNames("instagram", classes.button)}
		><IntagramIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://www.flickr.com/people/markaforster/"); }}
			className={classNames("flickr", classes.button)}
		><FlickrIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://dribbble.com/markforster"); }}
			className={classNames("dribbble", classes.button)}
		><DribbbleIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://vm.tiktok.com/ZSvGkVV4/"); }}
			className={classNames("tiktok", classes.button)}
		><TikTokIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://www.snapchat.com/add/markaforster"); }}
			className={classNames("snapchat", classes.button)}
		><SnapchatIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://trakt.tv/users/markforster"); }}
			className={classNames("trakt", classes.button)}
		><TraktIcon /></IconButton>
		<IconButton
			onClick={() => { this.onClick("https://github.com/markforster"); }}
			className={classNames("github", classes.github)}
		><GitHubIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://bitbucket.org/markforster/"); }}
			className={classNames("bitbucket", classes.button)}
		><BitBucketIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://gitlab.com/markforster"); }}
			className={classNames("gitlab", classes.button)}
		><GitLabIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://www.npmjs.com/~markforster"); }}
			className={classNames("npm", classes.button)}
		><NPMIcon /></IconButton>
	
		<IconButton
			onClick={() => { this.onClick("https://www.goodreads.com/user/show/74144787-mark-forster"); }}
			className={classNames("goodreads", classes.button)}
		><GoodReadsIcon /></IconButton>
	
		<IconButton
			onClick={() => { this.onClick("https://www.last.fm/user/markaforster"); }}
			className={classNames("lastfm", classes.button)}
		><LastFMIcon /></IconButton>
	
		{/* <IconButton
			onClick={() => { this.onClick("TODO"); }}
			className={classNames("discord", classes.button)}
		><DiscordIcon /></IconButton> */}

		<IconButton
			onClick={() => { this.onClick("https://foursquare.com/markforster"); }}
			className={classNames("foursquare", classes.button)}
		><FourSquareIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.pinterest.co.uk/markforster/"); }}
			className={classNames("pintrest", classes.button)}
		><PintrestIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.reddit.com/user/mark-forster"); }}
			className={classNames("reddit", classes.button)}
		><RedditIcon /></IconButton>
		
		<IconButton
			onClick={() => { this.onClick("https://flipboard.com/@markforster"); }}
			className={classNames("flipboard", classes.button)}
		><FlipBoardIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://soundcloud.com/markaforster"); }}
			className={classNames("soundcloud", classes.button)}
		><SoundcloudIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.twitch.tv/markforster"); }}
			className={classNames("twitch", classes.button)}
		><TwitchIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.tumblr.com/blog/markforster/"); }}
			className={classNames("tumblr", classes.button)}
		><TumblrIcon /></IconButton>

		<IconButton
			onClick={() => { this.onClick("https://www.youtube.com/channel/UCOaPQHwZFucTaM24up1ilOQ?view_as=subscriber"); }}
			className={classNames("youtube", classes.button)}
		><YoutubeIcon /></IconButton>
		</>);	
	}
}

export default withStyles(styles, { withTheme: true })(Pins);