import React from 'react';
import { withStyles } from '@material-ui/core';

// import styles from "./styles";
const styles = {};

enum BannerState {
	INIT = 0,
	LOADING = 1,
	LOADED = 2,
	INTRO = 3,
	INTRO_COMPLETE = 4,
}

interface IFadingBannerProps
{
	classes: any;
	images: string[];
	fadeSpeed?: number;
	pauseDuration?: number;
}

interface IFadingBannerState
{
	loaded: boolean;
}

class FadingBanner extends React.Component<IFadingBannerProps, IFadingBannerState>
{
	private bannerState: BannerState = BannerState.LOADED;

	private myRef: any = null;
  private interval: any = null;
	private images: any[] = [];

  private afID: number = 0;
  private timeStamp: number | undefined = undefined;
  private fadeSpeed: number = 5000;
  private waitSpeed: number = 0;
  private waitTime: number = 5000;
	private waiting: boolean = false;
	
	private opacity = 1.0;

	public constructor (props: IFadingBannerProps)
	{
		super(props);

		this.state = {loaded: false};
		
		// if (!props.fadeSpeed) { props.fadeSpeed = 2000;}
		if (props.fadeSpeed) { this.fadeSpeed = props.fadeSpeed;}
		if (props.pauseDuration) { this.waitTime = props.pauseDuration;}

		this.myRef = React.createRef();
	}

	private loadImages = () =>
	{
		// Load the images
		const promises: Promise<any>[] = [];

		this.props.images.forEach((url: string, index: number) => 
		{
			promises.push(new Promise((resolve, reject) => 
			{
				let img = new Image();
				
				img.addEventListener('load', e => 
				{
					this.images.push(img);
					resolve(img)
				});
				
				img.addEventListener('error', () => 
				{
					reject(new Error(`Failed to load image's URL: ${url}`));
				});

				img.src = url;

			}));
		});

		Promise.all(promises).then(() =>
		{
			// console.log("Loaded images");
			// this.setState({loaded: true});
			// this.bannerState = BannerState.LOADED;
			this.afID = window.requestAnimationFrame( this.step);
		});
	}

	private step = (timeStamp: number) =>
  {
    if (this.timeStamp === undefined) { this.timeStamp = timeStamp; }
    
    const elapsed = timeStamp - this.timeStamp; 

		this.timeStamp = timeStamp;
		
		if (this.bannerState === BannerState.INIT)
		{
			// Start loading the images!
			this.loadImages();
			this.bannerState = BannerState.LOADING;
		}
		else if (this.bannerState === BannerState.LOADED)
		{
			this.bannerState = BannerState.INTRO;
			this.setState({loaded: true});
		}
		else if (this.bannerState === BannerState.INTRO)
		{
			this.bannerState = BannerState.INTRO_COMPLETE;
			// We are fading in the images
		}
		else if (this.bannerState === BannerState.INTRO_COMPLETE)
		{
			if (this.waiting === false)
			{
				const div: HTMLElement = (this.myRef.current as HTMLElement);

				if (div && div.children)
				{
					
					const nodes: HTMLElement[] = (this.myRef.current.children as HTMLElement[]);

					nodes[nodes.length - 1].style.opacity = `${this.opacity}`;

					this.opacity -= (elapsed / this.fadeSpeed);

					if (this.opacity <= 0)
					{
						const t = div.removeChild(nodes[nodes.length-1]);
						div.prepend(t);
						t.style.opacity = `1.0`;
						this.opacity = 1.0;
						this.waiting = true;
					}

				}
			}
			else
			{
				this.waitSpeed += elapsed;

				if (this.waitSpeed >= this.waitTime)
				{
					this.waitSpeed = 0.0;
					this.waiting = false;
				}
			}
		}

    this.afID = window.requestAnimationFrame( this.step);
	}
	
	public componentDidMount = () =>
	{
		this.step(0);
	}

	public componentWillUnmount = () =>
  {
    if (this.afID) {  window.cancelAnimationFrame(this.afID); this.afID = 0;}
  }

	public render = () =>
	{         
		if (!this.state.loaded) { return (null); }

		return (<div className={"image-rotator"} style={{}} ref={this.myRef}>
			{this.props.images.map((image: string, index: number) => 
			{
				return (<div style={{backgroundImage: `url('${image}')`}} key={index}></div>);
			})}
		</div>);
	}
}

export default withStyles(styles, { withTheme: true })(FadingBanner);