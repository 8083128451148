import { createMuiTheme, SimplePaletteColorOptions } from '@material-ui/core/styles';
// const t: SimplePaletteColorOptions = null;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#FCE4D4",
      main: "#F4A470",
      dark: "#EC7841",  
    },
    secondary: {
      light: "#DFE9F0",
      main: "#93B6CE",
      dark: "#648EB0",  
    }
  },
  typography: {
    
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(','),
    // fontFamily: "Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace"

  },
});

export default theme;