import { createStyles, Theme } from '@material-ui/core';
// import Image from './DSC_0333.JPG'; // Import using relative path

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 275,
    flexGrow: 1,
    marginTop:"0rem",
  },
  bannerTop:{
    // backgroundImage: `url(${Image})`
    
  },

  appWelcome: {
    textAlign: "center",
    margin: theme.spacing(1),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  paper: {
    // textAlign: "center"
  },
  
  section:
  {
    margin: theme.spacing(4, 2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
	},
	large: {
		width: "8rem",
		height: "8rem",
    border: "solid 0.375rem #fff",
	},
  button:
  {
    borderRadius: 50,
    boxShadow: 'none',
    margin: theme.spacing(1),
    backgroundColor: '#ccc',
    "&:hover":{
    },
	},
	
  
  canvasContainer: {
    width: "100%",
    height: "10rem",
  }
  
  // button:
});

export default styles;